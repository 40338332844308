<template>
  <div class="aside-item__wrap">
    <a :href="item.url" class="medication-aside__item aside-item" v-for="(item, ind) in items" :key="ind">
      <img class="aside-item__image" :src="item.icon" />
      <div class="aside-item__title" v-html="item.title"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: "AsideLinks",
  props: {
    items: Array,
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.aside-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  max-width: fit-content;
  // height: 24px;

  // &_white {
  //   .aside-item {
  //     &__title {
  //       color: #ffffff;
  //     }
  //     &__button {
  //       color: #830051;
  //       background-color: #ffffff;

  //       &:hover {
  //         background-color: #ebefee;
  //       }
  //     }
  //   }
  // }

  &__wrap {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;

    @media screen and (max-width: 1220px) {
      margin-right: 32px;
      margin-bottom: 0;
      grid-template-columns: repeat(2, 1fr);
      &:last-child {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-right: 16px;
      grid-template-columns: 1fr;
    }
  }

  @media screen and (max-width: 1220px) {

  }

  @media screen and (max-width: 767px) {

  }

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin: auto 0 auto 8px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #830051;
    text-decoration: underline;
  }

  &__image {
    width: 24px;
    // height: 100%;
  }

  // &__link {
  //   margin-bottom: 16px;
  //   display: block;
  //   font-family: "Roboto", sans-serif;
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 16px;
  //   line-height: 22px;
  //   text-decoration-line: underline;
  //   color: #830051;
  //   transition: 0.3s;
  //   cursor: pointer;

  //   &:hover {
  //     color: #9a3374;
  //   }
  // }
}
</style>
