<template>
  <div>
    <div
      class="medication-detail"
      v-if="MedicationDetail && Object.keys(MedicationDetail).length"
    >
      <DrugsSlider
        :banners="MedicationDetail.banners"
        :color="MedicationDetail.color"
        v-if="MedicationDetail.banners && MedicationDetail.banners.length"
      />
      <Breadcrumbs
        :pages="[
          { name: 'Препараты АЗ', link: { name: 'Medications' } },
          { name: MedicationDetail.name },
        ]"
      />
      <div class="content">
        <div class="medication-detail__row">
          <div class="medication-detail__list">
            <div class="material__tags">
              <div
                class="material__tag"
                v-for="tag in MedicationDetail.nosology"
                :key="tag.id"
                v-html="tag.title"
              ></div>
              <Favorite
                class="large ml-auto ml-xs-0"
                :watchLater="MedicationDetail.watch_later"
                :favorite="MedicationDetail.favorite"
                @watchLater="
                  watchLater(
                    MedicationDetail.watch_later ? 'DELETE' : 'POST',
                    MedicationDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    MedicationDetail.favorite ? 'DELETE' : 'POST',
                    MedicationDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>
            <div class="materials">
              <FileBlockNew
                v-for="material in MedicationDetail.links"
                :key="material.id"
                :material="material"
                :kind="material.file ? 'file' : 'link'"
                @linkClick="linkClick(material.name)"
              />
            </div>
            <div v-if="MedicationDetail.about_blocks">
              <InfoDrop
                v-for="(item, ind) in MedicationDetail.about_blocks"
                :key="ind"
                :item="item"
                :fixed="true"
                :color="MedicationDetail.color"
              />
            </div>
            <!-- <div class="medication-detail__drug-title mt-10">
              Препарат {{ MedicationDetail.name }}
            </div> -->
            <br />
            <br />
            <div v-if="MedicationDetail.tabs">
              <div id="tabsTopAnchor"></div>
              <div class="medication-detail__tabs_compact">
                <div
                  class="medication-detail__tab"
                  v-for="(tab, tInd) in MedicationDetail.tabs"
                  :class="{
                    'medication-detail__tab_active': activeTab === tInd,
                  }"
                  @click="changeTab(tInd)"
                  :key="tInd"
                >
                  <div v-html="tab.colors"></div>
                  <div
                    class="medication-detail__tab-icon"
                    v-html="tab.first_icon"
                  ></div>
                  <div
                    class="medication-detail__tab-text"
                    v-html="tab.title"
                  ></div>
                  <div class="medication-detail__tab-shape">
                    <svg
                      width="52"
                      height="10"
                      viewBox="0 0 52 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M26 10C26 10 17 1 0 0L52 0C36 1 26 10 26 10Z"
                        fill="var(--bgColor)"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div
                class="medication-detail__tabs_full"
                v-if="MedicationDetail.tabs.every((el) => el.second_icon)"
              >
                <div
                  class="medication-detail__tab"
                  @click="changeTab(tInd)"
                  :class="{
                    'medication-detail__tab_active': activeTab === tInd,
                  }"
                  v-for="(tab, tInd) in MedicationDetail.tabs"
                  :key="tInd"
                >
                  <div
                    class="medication-detail__tab-icon"
                    v-html="tab.second_icon"
                  ></div>
                  <div
                    class="medication-detail__tab-text"
                    v-html="tab.title"
                  ></div>
                </div>
              </div>

              <div
                v-for="(tab, tInd) in MedicationDetail.tabs"
                :key="tInd"
                v-show="activeTab === tInd"
              >
                <div v-for="(item, ind) in tab.content" :key="ind">
                  <TabSlider
                    v-if="
                      tab.materials && tab.materials.length && item.checkbox
                    "
                    :tabs="materialsList(tab.materials)"
                  ></TabSlider>
                  <InfoDrop
                    :item="item"
                    :fixed="true"
                    :color="MedicationDetail.color"
                  />
                </div>
              </div>
            </div>
            <CoolLightBox
              :items="items"
              :index="index"
              @close="onClose()"
            ></CoolLightBox>
          </div>
          <AsideLinks :items="MedicationDetail.side_links" />
        </div>
      </div>
    </div>
    <div v-else-if="MedicationDetail === 404">
      <Choch class="inPage" />
    </div>
    <div v-else-if="MedicationDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../components/pageComponents/InfoDrop.vue";
import FileBlockNew from "../components/pageComponents/FileBlockNew.vue";
import DrugsSlider from "@/components/main/DrugsSlider.vue";
import TabSlider from "@/components/pageComponents/TabSlider.vue";
import AsideLinks from "../components/pageComponents/AsideLinks.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import Favorite from "@/components/pageComponents/Favorite.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  metaInfo() {
    if (this.MedicationDetail.name) {
      return { title: this.MedicationDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.MedicationDetail.name };
    }
  },
  name: "MedicationDetailNew",
  components: {
    Breadcrumbs,
    AsideLinks,
    InfoDrop,
    FileBlockNew,
    DrugsSlider,
    TabSlider,
    Choch,
    Error,
    Favorite,
    CoolLightBox,
  },
  data: (vm) => ({
    activeTab: 0,
    items: [],
    index: null,
    media: {
      mobile: 767,
      tablet: 1220,
      desktop: 1919,
      desktop_l: 2559,
      desktop_xl: 3839,
      desktop_xxl: 9999999,
    },
    pages: {
      textmaterial: "TextDetail",
      videomaterial: "VideoDetail",
      podcast: "PodcastDetail",
    },
  }),
  computed: {
    ...mapGetters(["MedicationDetail"]),
  },
  methods: {
    ...mapActions([
      "fetchMedicationDetail",
      "fetchMedicationFavorite",
      "fetchMedicationWatchLater",
      "fetchMedicationHistory",
    ]),
    changeTab(tInd) {
      this.$set(this, "activeTab", tInd);
      const tabBtn = document.querySelector(
            `#tabsTopAnchor`
          );
          if (tabBtn) {
            const scrollHeight = this.getScrollHeight(); // Получить высоту прокрутки в зависимости от ширины экрана
            const topPosition =
              tabBtn.getBoundingClientRect().top +
              window.pageYOffset -
              scrollHeight;
            window.scrollTo({ top: topPosition, behavior: "smooth" });
          }
    },
     getScrollHeight() {
      if (window.innerWidth >= 1200) {
        return 150; // Прокрутка для широкого экрана
      } else if (window.innerWidth >= 768) {
        return 30; // Прокрутка для узкого экрана
      } else {
        return 10;
      }
    },
    materialsList(materials) {
      return materials.map((el) => ({
        ...el,
        object: { ...el.object, page: this.pages[el.type] },
      }));
    },
    async watchLater(method, slug, filter, favoritePage) {
      await this.fetchMedicationWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchMedicationDetail({ slug: this.$route.params.medication });
    },
    async favorite(method, slug, filter, favoritePage) {
      await this.fetchMedicationFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchMedicationDetail({ slug: this.$route.params.medication });
    },
    linkClick(title) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "instruction download", {
          "instruction download": {
            "medications page name": title,
            ...this.$root.ymFields,
          },
        });
      }
    },
    // getKind(type){
    //   if (material.file.url) {
    //    return this.material.kind
    //   }
    // }
    openImage(img) {
      this.items[0] = img;
      this.index = 0;
    },
    onClose() {
      this.index = null;
      this.items[0] = "";
    },
  },
  async mounted() {
    if (!this.$route.query.access) {
      await this.fetchMedicationDetail({ slug: this.$route.params.medication });
      await this.fetchMedicationHistory(this.$route.params.medication);
    }
    const vm = this;
    document.addEventListener("click", function (e) {
      for (
        let target = e.target;
        target && target != this;
        target = target.parentNode
      ) {
        if (target.matches(".full-screen__trigger")) {
          if (target.parentElement.querySelector("img")) {
            vm.openImage(
              target.parentElement.querySelector("img").getAttribute("src")
            );
          } else if (target.parentElement.querySelector("video")) {
            vm.openImage(
              target.parentElement.querySelector("video").getAttribute("src")
            );
          }
        }
      }
    });
  },
  watch: {
    MedicationDetail() {
      localStorage.setItem("medicationBlock", this.MedicationDetail.slug);
    },
  },
};
</script>

<style lang="scss" scoped>
.materials {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(182px, 1fr));
  gap: 16px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.medication-detail {
  &__tabs {
    &_full {
      padding: 32px 0;
      background-color: #fff;
      margin-top: -66px;
      position: relative;
      z-index: 2;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 296px));
      column-gap: 16px;
    }

    &_compact {
      position: sticky;
      top: 0;
      padding: 0 0 16px;
      background-color: #fff;
      z-index: 1;
      display: grid;
      grid-auto-columns: minmax(100px, 296px);
      grid-auto-flow: column;
      column-gap: 16px;

      @media screen and (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-auto-columns: auto;
        row-gap: 16px;
        grid-auto-flow: dense;
        // position: static;
      }

      & .medication-detail {
        &__tab {
          position: relative;
          padding: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 99px;
          background: var(--bgColor);
          min-height: 42px;
          cursor: pointer;

          &-icon {
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            flex-shrink: 0;
            margin-right: 16px;
          }

          &-text {
            width: calc(100% - 70px);
            margin: 0 auto;
            font-family: "Roboto Slab", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: var(--textColor);
          }

          &-shape {
            display: none;
            position: absolute;
            top: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
          }

          &_active {
            & .medication-detail {
              &__tab {
                &-shape {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  &__list {
    width: 100%;
  }
  &__top {
    height: 400px;
    padding: 32px 0;
    background-color: #ebefee;

    @media screen and (max-width: 1220px) {
      height: auto;
    }
  }

  &__top-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  & .content {
    height: 100%;
  }

  &__drug-title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__text {
    padding: 32px 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1220px) {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__image-container {
    width: 588px;
    height: 322px;
    margin-left: auto;

    @media screen and (max-width: 1220px) {
      margin-left: 0;
      height: 262px;
      width: 100%;
      margin-top: 40px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__icons {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      margin-top: 0;
      margin-left: auto;
    }

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &__icon {
    margin-right: 8px;
    transition: 0.3s;

    & img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      object-position: center;
      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &_disable {
      pointer-events: none;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &__name {
    margin-bottom: 8px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #830051;
  }

  &__ingredient {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    color: #656969;
  }

  &__row {
    margin-top: 32px;
    display: grid;
    grid-template-columns: minmax(800px, 1fr) 220px;
    column-gap: 76px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
      margin-top: 32px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 16px;
    }
  }
}

.material {
  &__tags {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>

<style lang="scss">
.new-materials_btn {
  margin-right: 16px;
  @media screen and(max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }
}

.materials-in-body {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(3, minmax(182px, 1fr));
  gap: 16px;

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & .material {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    background-color: #f8f8f8;
    text-decoration: none;
    cursor: pointer;
    text-decoration: none !important;
    line-break: auto;

    &:hover {
      .material__title {
        color: #d0006f;
      }
    }

    &__title {
      margin-bottom: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #3c4242;
      transition: 0.3s;
      text-decoration: none !important;
    }

    &__link {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #d0006f;
      word-break: break-all;
      text-decoration: none !important;

      &-icon {
        margin-right: 8px;
      }

      &-weight {
        margin-left: 8px;
        color: #ec99c5;
        text-decoration: none !important;
      }
    }
  }
}
</style>
