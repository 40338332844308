<template>
  <div>
    <div v-if="MedicationDetail.template_type == 0">
      <MedicationDetail />
    </div>
    <div v-else-if="MedicationDetail.template_type == 1">
      <MedicationDetailNew />
    </div>
    <div v-else>
      <MedicationDetailTabs />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MedicationDetail from "./MedicationDetail";
import MedicationDetailNew from "./MedicationDetailNew.vue";
import MedicationDetailTabs from "./MedicationDetailTabs.vue";
export default {
  name: "DrugsTemplates",
  components: {
    MedicationDetail,
    MedicationDetailNew,
    MedicationDetailTabs
  },
  computed: {
    ...mapGetters(["MedicationDetail"]),
  },
  methods: {
    ...mapActions(["fetchMedicationDetail"]),
  },
};
</script>

<style></style>
