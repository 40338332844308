var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.MedicationDetail && Object.keys(_vm.MedicationDetail).length )?_c('div',{staticClass:"medication-detail"},[_c('div',{staticClass:"medication-detail__top"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"medication-detail__top-container"},[_c('div',{staticClass:"medication-detail__text"},[_c('div',{staticClass:"medication-detail__name-container"},[_c('div',{staticClass:"medication-detail__name",domProps:{"innerHTML":_vm._s(_vm.MedicationDetail.name)}}),_c('div',{staticClass:"medication-detail__ingredient",domProps:{"innerHTML":_vm._s(_vm.MedicationDetail.substance)}})]),_c('div',{staticClass:"medication-detail__icons"},_vm._l((_vm.filteredIcons),function(cat){return _c('div',{key:cat.id,staticClass:"medication-detail__icon",class:{ 'medication-detail__icon_disable': !cat.active }},[(cat.active)?_c('router-link',{attrs:{"to":{ name: 'NosologyDetail', params: { slug: cat.slug } }}},[_c('img',{attrs:{"src":_vm.$root.host + cat.icon}})]):_c('div',[_c('img',{attrs:{"src":_vm.$root.host + cat.icon}})])],1)}),0)]),_c('div',{staticClass:"medication-detail__image-container"},[_c('img',{staticClass:"medication-detail__image",attrs:{"src":_vm.MedicationDetail.image}})])])])]),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Препараты АЗ', link: { name: 'Medications' } },
        { name: _vm.MedicationDetail.name } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"medication-detail__row"},[_c('div',{staticClass:"medication-detail__list"},[_c('div',{staticClass:"material__tags"},[_vm._l((_vm.MedicationDetail.nosology),function(tag){return _c('div',{key:tag.id,staticClass:"material__tag",domProps:{"innerHTML":_vm._s(tag.title)}})}),_c('Favorite',{staticClass:"large ml-auto ml-xs-0",attrs:{"watchLater":_vm.MedicationDetail.watch_later,"favorite":_vm.MedicationDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.MedicationDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.MedicationDetail.favorite ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )}}})],2),_c('div',{staticClass:"materials"},_vm._l((_vm.MedicationDetail.files),function(material){return _c('FileBlock',{key:material.id,attrs:{"material":material,"kind":material.file ? 'file' : 'link'},on:{"linkClick":function($event){return _vm.linkClick(material.name)}}})}),1),(!_vm.MedicationDetail.hide_content)?_c('div',_vm._l((_vm.blocks),function(item,ind){return _c('InfoDrop',{key:ind,attrs:{"item":item}})}),1):_vm._e()]),_c('RightAside',{attrs:{"items":_vm.asideItems},on:{"calendarClick":_vm.calendarClick}})],1)])],1):(_vm.MedicationDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.MedicationDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }