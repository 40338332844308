var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.MedicationDetail && Object.keys(_vm.MedicationDetail).length)?_c('div',{staticClass:"medication-detail"},[(_vm.MedicationDetail.banners && _vm.MedicationDetail.banners.length)?_c('DrugsSlider',{attrs:{"banners":_vm.MedicationDetail.banners,"color":_vm.MedicationDetail.color}}):_vm._e(),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Препараты АЗ', link: { name: 'Medications' } },
        { name: _vm.MedicationDetail.name } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"medication-detail__row"},[_c('div',{staticClass:"medication-detail__list"},[_c('div',{staticClass:"material__tags"},[_vm._l((_vm.MedicationDetail.nosology),function(tag){return _c('div',{key:tag.id,staticClass:"material__tag",domProps:{"innerHTML":_vm._s(tag.title)}})}),_c('Favorite',{staticClass:"large ml-auto ml-xs-0",attrs:{"watchLater":_vm.MedicationDetail.watch_later,"favorite":_vm.MedicationDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.MedicationDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.MedicationDetail.favorite ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )}}})],2),_c('div',{staticClass:"materials"},_vm._l((_vm.MedicationDetail.links),function(material){return _c('FileBlockNew',{key:material.id,attrs:{"material":material,"kind":material.file ? 'file' : 'link'},on:{"linkClick":function($event){return _vm.linkClick(material.name)}}})}),1),(_vm.MedicationDetail.about_blocks)?_c('div',_vm._l((_vm.MedicationDetail.about_blocks),function(item,ind){return _c('InfoDrop',{key:ind,attrs:{"item":item,"fixed":true,"color":_vm.MedicationDetail.color}})}),1):_vm._e(),(
              _vm.MedicationDetail.materials && _vm.MedicationDetail.materials.length
            )?_c('TabSlider',{attrs:{"tabs":_vm.materialsList}}):_vm._e(),(_vm.MedicationDetail.content_blocks)?_c('div',_vm._l((_vm.MedicationDetail.content_blocks),function(item,ind){return _c('InfoDrop',{key:ind,attrs:{"item":item,"fixed":true,"color":_vm.MedicationDetail.color}})}),1):_vm._e(),_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){return _vm.onClose()}}})],1),_c('AsideLinks',{attrs:{"items":_vm.MedicationDetail.side_links}})],1)])],1):(_vm.MedicationDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.MedicationDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }