var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tabsList && _vm.tabsList.length && _vm.current_tab)?_c('div',{staticClass:"tabs-slider"},[_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"tabs-slider__title mr-8 mb-0"},[_vm._v("Материалы по теме")]),_c('div',{staticClass:"tabs-slider__tab-row"},_vm._l((_vm.tabsList),function(tab,ind){return _c('div',{key:ind,staticClass:"tabs-slider__tab",class:{
          'tabs-slider__tab_active': _vm.current_tab.tab_name === tab.tab_name,
        },on:{"click":function($event){_vm.current_tab = tab}}},[_vm._v(" "+_vm._s(tab.tab_name)+" ")])}),0)]),_c('div',{staticClass:"tabs-slider__tab-content"},[(_vm.filteredTabMaterials.length)?_c('VueSlickCarousel',_vm._b({key:_vm.current_tab.id,ref:"tabsSlider",on:{"init":_vm.onInitCarousel,"reInit":_vm.onInitCarousel}},'VueSlickCarousel',_vm.settings,false),_vm._l((_vm.filteredTabMaterials),function(object){return _c('Material',{key:object.object_id,attrs:{"item":object.object,"page":object.object.page},on:{"clickMaterials":_vm.clickMaterials,"watchLater":function($event){return _vm.watchLater(
            object.object.watch_later ? 'DELETE' : 'POST',
            object.object.slug,
            object.type
          )},"favorite":function($event){return _vm.favorite(
            object.object.favorite ? 'DELETE' : 'POST',
            object.object.slug,
            object.type
          )}}})}),1):_vm._e(),(_vm.showArrows)?_c('div',{staticClass:"tabs-slider__arrows"},[_c('div',{staticClass:"tabs-slider__arrow mr-3",on:{"click":function($event){return _vm.$refs.tabsSlider.prev()}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11.25 4.50012L3.75 12.0001L11.25 19.5001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}}),_c('path',{attrs:{"d":"M5.25 12.0001L20.25 12.0001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])]),_c('div',{staticClass:"tabs-slider__arrow ml-3",on:{"click":function($event){return _vm.$refs.tabsSlider.next()}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12.75 4.50012L20.25 12.0001L12.75 19.5001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}}),_c('path',{attrs:{"d":"M18.75 12.0001L3.75 12.0001","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"square"}})])])]):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }